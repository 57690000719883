/* Container styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fdf2f6; /* Very light pink */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: auto;
}

/* Order Success Box */
.order-success {
  background-color: #ffffff; /* White background for contrast */
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

/* Box styling */
.box {
  background-color: #e0bbf2; /* Light lavender/pastel blue */
  border-radius: 10px;
  padding: 15px;
  margin: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

/* Headings and Text */
h1 {
  color: #ff9aa2; /* Soft baby pink */
  font-size: 2em;
  margin-bottom: 20px;
}

p {
  font-size: 1.1em;
  color: #333; /* Dark gray for readability */
}

.intro {
  font-style: italic;
  color: #888; /* Gray for less emphasis */
}

/* Loading and error messages */
.loading, .error {
  font-size: 1.2em;
  color: #ff9aa2; /* Soft baby pink */
  margin: 20px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .order-success {
    padding: 15px;
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 1em;
  }

  .box {
    padding: 10px;
  }
}


