.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px; /* Add padding to prevent content from touching the edges */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px; /* Limit the max-width for better layout control */
  aspect-ratio: 1; /* Maintain a 1:1 aspect ratio */
  position: relative;
  margin-bottom: 20px; /* Add space below the image container */
}

.centered-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer-placeholder {
  height: 50px; /* Adjust the height as needed */
}

/* Media Queries */
@media (max-width: 767px) {
  .image-container {
    max-width: 90%; /* Ensure the image container doesn't exceed screen width */
    aspect-ratio: 1.5; /* Adjust the aspect ratio for smaller screens */
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .image-container {
    max-width: 80%; /* Adjust max-width for tablets */
    aspect-ratio: 1.2; /* Adjust the aspect ratio for tablets */
  }
}

@media (min-width: 1024px) {
  .image-container {
    max-width: 70%; /* Adjust max-width for larger screens */
    aspect-ratio: 1; /* Maintain a 1:1 aspect ratio for larger screens */
  }
}

  