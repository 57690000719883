/*.navbar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure it is above other content */
    /*background-color: #fff; /* Adjust as needed */
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow */
  /*}/* 

/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff0f5; /* light pink background */
  padding: 15px 25px;
  box-shadow: 0 2px 10px rgba(255, 182, 193, 0.4); /* soft pink shadow */
  border-bottom: 2px solid #ffc0cb; /* pastel pink border */
}

.navbar-left .navbar-logo {
  font-size: 1.8em;
  font-weight: bold;
  color: #ff69b4; /* hot pink for logo text */
  text-decoration: none;
  font-family: 'Comic Sans MS', 'cursive'; /* playful font */
}

.navbar-center .navbar-link {
  margin: 0 15px;
  font-size: 1em;
  color: #ff69b4;
  text-decoration: none;
  font-family: 'Comic Sans MS', 'cursive';
}

.navbar-link:hover {
  color: #db7093; /* deep pink on hover */
  text-decoration: underline;
}

.navbar-right {
  display: flex;
  align-items: center;
}

/* Cart styling */
.cart-dropdown {
  position: relative;
  margin-right: 20px;
}

.navbar-cart {
  color: #ff69b4;
  text-decoration: none;
  font-family: 'Comic Sans MS', 'cursive';
  position: relative;
}

.cart-content {
  display: none;
  position: absolute;
  right: 0;
  top: 40px;
  background-color: #ffe4e1; /* soft pink background */
  padding: 15px;
  box-shadow: 0 4px 8px rgba(255, 182, 193, 0.5); /* soft pink shadow */
  width: 200px;
  border-radius: 10px;
}

.cart-dropdown:hover .cart-content {
  display: block;
}

.view-cart-btn {
  display: block;
  margin-top: 10px;
  text-align: center;
  background-color: #ff69b4; /* hot pink button */
  color: white;
  padding: 5px;
  border-radius: 20px;
  text-decoration: none;
  font-family: 'Comic Sans MS', 'cursive';
}

.view-cart-btn:hover {
  background-color: #db7093; /* deep pink on hover */
}

/* Avatar styling */
.user-dropdown {
  position: relative;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #ff69b4;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.avatar:hover {
  transform: scale(1.1); /* slightly enlarges avatar on hover */
}

.user-menu {
  display: none;
  position: absolute;
  right: 0;
  top: 55px;
  background-color: #ffe4e1; /* soft pink */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(255, 182, 193, 0.5); /* soft pink shadow */
  width: 160px;
  border-radius: 10px;
}

.user-dropdown:hover .user-menu {
  display: block;
}

.user-link {
  display: block;
  margin: 5px 0;
  color: #ff69b4;
  text-decoration: none;
  font-family: 'Comic Sans MS', 'cursive';
  font-size: 0.9em;
  padding: 5px 0;
}

.user-link:hover {
  color: #db7093;
  text-decoration: underline;
}
