.cancel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('https://www.sanrio.com/cinnamoroll-bg.png') no-repeat center center;
    background-size: cover;
    padding: 20px;
    text-align: center;
}

.cancel-content {
    background: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    border: 3px solid #ffb6c1;
    font-family: 'Comic Sans MS', 'Comic Sans', cursive;
}

.cancel-content h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ff69b4; /* Hot pink */
    text-shadow: 1px 1px 2px #ff1493;
}

.cancel-content p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #8b008b; /* Dark magenta */
}

.back-home-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #ff69b4;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
}

.back-home-button:hover {
    background-color: #ff1493;
    transform: scale(1.05);
}

/* Responsive styles */
@media (max-width: 768px) {
    .cancel-content {
        padding: 20px;
    }

    .cancel-content h1 {
        font-size: 1.5rem;
    }

    .cancel-content p {
        font-size: 1rem;
    }

    .back-home-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .cancel-content {
        padding: 15px;
    }

    .cancel-content h1 {
        font-size: 1.2rem;
    }

    .cancel-content p {
        font-size: 0.9rem;
    }

    .back-home-button {
        padding: 6px 12px;
        font-size: 0.8rem;
    }
}
