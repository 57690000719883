@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

/* Container styling */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Flexbox layout for cards */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Space between cards */
  justify-content: center;
}

/* Card styling */
.card {
  background-color: #ffffff; /* White background for cards */
  border-radius: 12px; /* Rounded corners for a soft look */
  width: 180px; /* Smaller width for cards */
  height: 240px; /* Smaller height for cards */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

/* Card image styling */
.card img {
  width: 100%;
  height: 140px; /* Adjusted height for image */
  object-fit: cover;
  border-bottom: 2px solid #f0f0f0; /* Light border between image and text */
  border-radius: 12px 12px 0 0; /* Rounded corners at the top */
}

/* Card body styling */
.card-body {
  padding: 10px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7); /* Translucent white background */
  border-radius: 0 0 12px 12px; /* Rounded corners at the bottom */
}

/* Card title styling */
.card-title {
  margin: 0;
  font-size: 1em; /* Smaller font size */
  font-weight: 400; /* Normal weight for a handwritten look */
  color: lightpink;
  font-family: 'Great Vibes', cursive;
  text-transform: capitalize; /* Capitalize first letter */
}

/* Hover effect for cards */
.card:hover {
  transform: scale(1.05); /* Slightly scale up on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive typography and card size */
@media (max-width: 768px) {
  .card {
    width: 160px; /* Smaller width for mobile */
    height: 220px; /* Adjusted height for mobile */
  }
  .card-title {
    font-size: 0.9em; /* Smaller font size for mobile */
  }
}

/* Shop.css */

/* General container styles */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  background-color: #f0f4f8; /* Light background color */
}

/* Flex container for product cards */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between cards */
}

/* Loading text styles */
.loading-message {
  font-size: 24px; /* Increase font size for visibility */
  font-weight: bold;
  color: #333; /* Darker text color for contrast */
  animation: fadeIn 1s ease-in-out; /* Fade in animation */
}

/* Cute spinner styles */
.spinner {
  border: 8px solid #f3f3f3; /* Light gray background for spinner */
  border-top: 8px solid #3498db; /* Blue color for the top part */
  border-radius: 50%; /* Circular shape */
  width: 60px; /* Size of the spinner */
  height: 60px; /* Size of the spinner */
  animation: spin 2s linear infinite; /* Spinning animation */
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Fade in animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

