.order-container {
  max-width: 600px;
  margin: 0 auto;
  background-color: rgba(255, 176, 193, 0.8); /* Slightly transparent pink background */
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(10px); /* Glass effect on the container */
}

.order-header {
  text-align: center;
  color: #fdf8fb;
  font-size: 24px;
  margin-bottom: 20px;
}

.order-form {
  background: rgba(255, 255, 255, 0.3); /* White background with transparency for glass effect */
  backdrop-filter: blur(10px); /* Glass effect */
  border-radius: 10px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;
  max-width: 500px;
}

.form-group {
  margin-bottom: 0;
}

.input-container input[type='text'] {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ff66cc;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.5); /* Slightly transparent background for inputs */
}

.button-container {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

@media (max-width: 768px) {
  .order-form {
    grid-template-columns: 1fr;
  }
}
