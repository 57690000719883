/* ProductForm.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  .form-container {
    max-width: 500px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .label {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: #333;
  }
  
  .input,
  .textarea {
    border-radius: 5px;
  }
  
  .btn {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    background-color: #ff66b3;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .btn:hover {
    background-color: #ff4da6;
  }
  
  /* Custom styling for input boxes */
  #productName::placeholder,
  #description::placeholder,
  #price::placeholder,
  #quantity::placeholder {
    color: #aaa;
  }
  
  /* Custom styling for file input */
  .input[type="file"] {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
  }
  
  .input[type="file"]::placeholder {
    color: #aaa;
  }
  
  .input[type="file"]:focus {
    border-color: #ff66b3;
  }
  