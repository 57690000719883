.terms-service-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
}

h2, h3 {
  color: #555;
  margin-bottom: 16px;
  text-align: center;
}

p, ul {
  line-height: 1.6;
  margin-bottom: 16px;
  text-align: justify;
}

ul {
  list-style: disc inside;
  padding-left: 20px;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .terms-service-container {
    padding: 15px;
  }
}
