/* Profile.css */

.profile-container {
  max-width: 600px;

  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background: linear-gradient(135deg, #FFC0CB, #ADD8E6); /* Light pink to light blue gradient */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  margin-bottom: 20px;
  font-size: 24px;
  color: #444;
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Cute font */
}

.avatar-selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.avatar-link {
  margin: 10px;
}

.avatar {
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  border-radius: 50%; /* Make it round */
  transition: border-radius 0.3s ease; /* Smooth transition */
}

.avatar:hover {
  border-radius: 20%; /* Adjust the border radius on hover */
}

.personal-info-section {
  margin-top: 30px;
}

.personal-info-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-field {
  margin-bottom: 10px;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  width: 80%;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  transition: all 0.3s ease;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px 0 #007BFF;
  outline: none;
}

/* Button Styles */
.button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

/* Notification Styles */
.notification {
  position: fixed;
  top: -50px; /* Start hidden */
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1000;
}

.notification.show {
  top: 20px; /* Move into view */
  opacity: 1;
}

.notification.success {
  background-color: #4caf50; /* Green */
}

.notification.error {
  background-color: #f44336; /* Red */
}

.auth-prompt-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(135deg, #ffdee9, #b5fffc);
}

.auth-prompt {
  text-align: center;
  padding: 20px;
  max-width: 350px;
  width: 100%;
  background: #fff;
  border: 3px solid #ffb3c6;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  font-family: 'Comic Sans MS', sans-serif;
}

.auth-prompt h2 {
  font-size: 1.8rem;
  color: #ff69b4;
  margin-bottom: 15px;
  text-shadow: 1px 1px #ffccf9;
}

.auth-links {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-top: 20px;
}

.auth-link {
  display: inline-block;
  font-size: 1.1rem;
  padding: 8px 16px;
  color: #fff;
  background-color: #ff69b4;
  border-radius: 20px;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.auth-link:hover {
  background-color: #ff85a1;
  transform: scale(1.1);
}




  