.sizing-guide-container {
    background-size: cover;
    background-position: center;
    background-image: url('./images/sizing-guide.jpg');
    height: 1000px;
    padding: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sizing-guide-content {
    background-color: rgba(255, 255, 255, 0.7); /* Transparent white background */
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    
}

.sizing-guide-heading {
    font-size: 24px;
    margin-bottom: 20px;
}

.sizing-guide-text {
    margin-bottom: 20px;
}

.sizing-guide-steps {
    margin-bottom: 20px;
}

.size-heading,
.finger,
.measurement {
    padding: 10px;
    border-bottom: 2px solid #fa47b0;
    text-align: center;
    
}

.size-heading {
    font-weight: bold;
    color: #ff69b4; /* Pink color for headings */
}

.finger {
    color: #ff69b4; /* Pink color for finger names */
}


.sizing-guide-table:hover {
    font-weight: bold;
    color:rgb(249, 247, 253);
    background-color:black;
}

.sizing-guide-table {
    margin: 0 auto;
    font-weight:bold;
    border:2px solid black;
}

.sizing-guide-note {
    color: palevioletred;
    border: solid black 3px;
    padding: 10px;
    font-weight:bold;
}

.sizing-guide-note:hover{
    color:white;
    background-color: black;
}

.sizing-guide-heading{
    color: palevioletred;
    font-weight:bold;
}
