.about-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #FFF5EE;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 2rem auto;
    max-width: 900px;
    text-align: center;
}

.cute-image-container {
    text-align: center;
    margin-bottom: 1rem;
    border: 2px solid #FF69B4;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(255, 192, 203, 0.5);
    width: 100%;
    max-width: 300px;
}

.cute-image {
    width: 100%;
    border-radius: 50%;
}

.text-container {
    max-width: 800px;
    padding: 1rem;
    border: 2px solid #FF69B4;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(255, 192, 203, 0.5);
    margin-top: 2rem; /* Ensure there is enough space between the image and text */
}

.title {
    font-size: 3rem;
    color: #FF69B4;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(255, 192, 203, 0.5);
}

.description {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 1rem;
}

.description:last-child {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .title {
        font-size: 2.5rem;
    }

    .description {
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    .about-us-container {
        padding: 1rem;
    }

    .title {
        font-size: 2rem;
    }

    .description {
        font-size: 1rem;
    }
}

