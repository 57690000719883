.cart-page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cart-title {
  font-size: 24px;
  color: #FCA3B7; /* Strawberry pink */
  margin-bottom: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.item-details {
  flex: 1;
  display: flex;
  align-items: center;
  text-align: left;
}

.item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 15px;
}

.item-name {
  font-weight: bold;
  color: #333;
}

.item-price {
  color: #FCA3B7; /* Strawberry pink */
}

.item-actions {
  display: flex;
  align-items: center;
}

.quantity-input {
  width: 50px;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.delete-button {
  background-color: #FCA3B7; /* Strawberry pink */
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: black; /* Darker shade */
  color: #fff;
}

.clear-cart-button {
  background-color: #FCA3B7; /* Strawberry pink */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.clear-cart-button:hover {
  background-color: black; /* Darker shade */
  color: #fff;
}

.total-price {
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
  color: #FCA3B7; /* Strawberry pink */
}

.shipping-info {
  margin-top: 20px;
  color: #FCA3B7; /* Strawberry pink */
  font-style: italic;
}

.space {
  padding: 80px;
}

.order-button {
  background-color: #FCA3B7; /* Strawberry pink */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.order-button:hover {
  background-color: black; /* Darker shade */
  color: #fff;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  z-index: 1000;
}

.notification.success {
  background-color: #4CAF50;
}

.notification.error {
  background-color: #F44336;
}
