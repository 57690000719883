@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.product-container {
  background: linear-gradient(135deg, #ffe6f2, #ffb3d9); /* Soft pink gradient */
  border-radius: 15px; /* Rounder edges for a softer look */
  padding: 40px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  overflow: hidden; /* Prevent content overflow */
}

.product-name {
  font-family: 'Great Vibes', cursive;
  font-size: 36px;
  color: #ff66b2;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.product-name::after {
  content: '';
  display: block;
  height: 4px;
  background: #ff66b2;
  width: 50%;
  margin: 0 auto;
  transition: width 0.3s ease;
}

.product-name:hover::after {
  width: 100%;
}

.description {
  background-color: #fff; /* White background for contrast */
  border: 2px solid #ff66b2; /* Border for a cute frame */
  border-radius: 10px; /* Rounded corners */
  padding: 20px;
  margin: 20px 0; /* Space between elements */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.description p {
  font-family: 'Dancing Script', cursive;
  font-size: 18px;
  color: #ff66b2;
  line-height: 1.5;
  margin-bottom: 10px; /* Spacing between paragraphs */
  animation: fadeIn 0.5s ease-in;
}

.price {
  font-family: 'Dancing Script', cursive;
  font-size: 24px; /* Larger font size for emphasis */
  color: #ff66b2;
  margin-bottom: 20px; /* Space below price */
  background-color: #fff; /* White background */
  border: 2px solid #ff66b2; /* Border for a cute frame */
  border-radius: 10px; /* Rounded corners */
  padding: 15px; /* Padding for spacing */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.price .original-price {
  text-decoration: line-through; /* Strikethrough for original price */
  color: #d1d1d1; /* Light gray color for original price */
}

.quantity {
  font-family: 'Dancing Script', cursive;
  font-size: 18px;
  color: #ff66b2;
  margin-bottom: 20px;
  background-color: #fff; /* White background */
  border: 2px solid #ff66b2; /* Border for a cute frame */
  border-radius: 10px; /* Rounded corners */
  padding: 15px; /* Padding for spacing */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  text-align: left; /* Align text to the left */
}

.quantity input {
  background-color: #fff; /* White background for contrast */
  border: 2px solid #ff66b2; /* Cute pink border */
  border-radius: 10px; /* Rounded corners for softness */
  padding: 10px; /* Padding for a comfortable touch */
  font-size: 18px; /* Font size for readability */
  width: 60px; /* Fixed width for consistency */
  text-align: center; /* Center the text */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for interaction */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.quantity input:focus {
  border-color: #ff80b3; /* Change border color on focus */
  outline: none; /* Remove default outline */
  box-shadow: 0px 0px 10px rgba(255, 104, 172, 0.5); /* Enhanced shadow on focus for a glow effect */
}

.nailsizes {
  font-family: 'Dancing Script', cursive;
  font-size: 18px;
  color: #ff66b2;
  margin-bottom: 20px;
  background-color: #fff; /* White background */
  border: 2px solid #ff66b2; /* Border for a cute frame */
  border-radius: 10px; /* Rounded corners */
  padding: 15px; /* Padding for spacing */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.image-carousel {
  margin-bottom: 30px;
}

.image-carousel img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease; /* Smooth scaling effect */
}

.image-carousel img:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.add-to-cart-btn {
  background-color: #ff80b3;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.add-to-cart-btn:hover {
  background-color: #ff6699;
  transform: scale(1.05);
}

.size-link {
  color: blue;
  text-decoration: underline; /* Make size link more noticeable */
}

/* Notification styles */
.notification {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.notification.success {
  background-color: #4CAF50; /* Green */
  color: white;
}

.notification.error {
  background-color: #f44336; /* Red */
  color: white;
}

/* Loading Screen Styling */
.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #f9f0ff;
  color: #ff69b4;
  font-family: 'Comic Sans MS', 'Arial', sans-serif;
  text-align: center;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 0.5s ease-in-out;
}

.loading-text {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #ff69b4;
}

.heart-icon {
  font-size: 3rem;
  color: #ff69b4;
  animation: pulse 1.5s infinite;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .product-container {
    padding: 20px;
  }

  .product-name {
    font-size: 28px;
  }

  .description p, .nailsizes, .quantity {
    font-size: 16px;
  }

  .add-to-cart-btn {
    font-size: 16px;
    padding: 10px 20px;
  }

  .loading-text {
    font-size: 1.2rem;
  }

  .heart-icon {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 480px) {
  .product-container {
    padding: 15px;
  }

  .product-name {
    font-size: 24px;
  }

  .description p, .nailsizes, .quantity {
    font-size: 14px;
  }

  .add-to-cart-btn {
    font-size: 14px;
    padding: 8px 16px;
  }

  .loading-text {
    font-size: 1rem;
  }

  .heart-icon {
    font-size: 2rem;
  }
}
