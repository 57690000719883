.dev-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-family: 'Comic Sans MS', cursive, sans-serif;
    background-color: #fff0f6;
    border: 2px solid #ff69b4;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
    margin-top: 50px;
}

.image-container-dev {
    display: flex;
    justify-content: center;
    width: 100%;
}

.dev-info-image img {
    border-radius: 50%;
    border: 5px solid #ff69b4;
    width: 150px;
    height: 150px;
    object-fit: cover;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dev-info-text {
    text-align: center;
    margin-top: 10px;
    padding: 10px 20px;
    background: #ffe4e1;
    border-radius: 15px;
    border: 2px solid #ff69b4;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cute-heading {
    color: #ff69b4;
    font-size: 1.8em;
    margin-bottom: 10px;
    margin-top: 0;
}

.cute-paragraph {
    color: #333;
    font-size: 1.1em;
    line-height: 1.6;
    margin-top: 0;
}

@media (max-width: 768px) {
    .dev-info-container {
        padding: 15px;
    }

    .dev-info-image img {
        width: 120px;
        height: 120px;
    }

    .cute-heading {
        font-size: 1.5em;
    }

    .cute-paragraph {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .dev-info-container {
        padding: 10px;
    }

    .dev-info-image img {
        width: 100px;
        height: 100px;
    }

    .cute-heading {
        font-size: 1.2em;
    }

    .cute-paragraph {
        font-size: 0.9em;
    }
}
