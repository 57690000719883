/* General styling for body */
body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: #f5f5f5; /* Soft background color */
  font-family: Arial, sans-serif; /* Professional font */
}

/* Container styling */
.privacy-policy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 40px;
  max-width: 800px;
  width: 90%; /* Responsive width */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 10px; /* Rounded corners */
}

/* Header styling */
h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333; /* Darker text color */
  text-align: center;
}

/* Paragraph styling */
p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #666; /* Softer text color */
}

/* Button styling */
button {
  background-color: #4CAF50; /* Green button color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #45a049; /* Darker green on hover */
}
